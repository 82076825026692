import { Box, Group, Text, Button } from '@mantine/core'
import { useTranslation } from 'react-i18next'

export function EventEnded() {
  const { t } = useTranslation()

  return (
    <Box
      display="flex"
      sx={{
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 100,
        flexDirection: 'column',
      }}
    >
      <Group
        position="center"
        sx={{
          flexFlow: 'column',
        }}
      >
        <Text size="xl" weight={700}>
          {t('event.ended')}
        </Text>
        <Text size="md">{t('event.thank-you')}</Text>
        <Button mt={20} color="custom-primary-color.0" onClick={() => window.open('https://fenestrationcanada.ca', '_blank')}>
          {t('event.visit-website')}
        </Button>
      </Group>
    </Box>
  )
}
