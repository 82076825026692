/* eslint-disable @typescript-eslint/naming-convention */
import { TextInput, Group, Button, Text, Box, Image, ActionIcon, Checkbox, Radio, Tooltip, Textarea, Anchor } from '@mantine/core'
import { UseFormReturnType, useForm } from '@mantine/form'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { Logo } from 'components/logo'
import { useMediaQuery } from '@mantine/hooks'
import { Source, Visitor } from 'api/dto/visitor'
import { useTradeshow } from 'api/query/tradeshow'
import { useAddVisitor } from 'api/query/visitor'
import { redirectToCheckout } from 'utils/stripe'
import { showNotification } from '@mantine/notifications'
import { Loading } from 'components/loading/loading'
import { RichTextEditor } from 'components/text/rich-text-editor'
import { useTradeshowPrices } from 'api/query/price'
import isEmpty from 'lodash/isEmpty'
import isEmail from 'validator/lib/isEmail'
import dayjs from 'dayjs'
import { EventEnded } from 'components/event-end/event-ended'

type RegisteredVisitor = Partial<Visitor> & { price: string; addons?: string[]; companions?: { firstName: string; lastName: string }[] }

export const RegisterNewVisitor = () => {
  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation()
  const { tradeshowId } = useParams()
  const navigate = useNavigate()
  const { data: tradeshow, isLoading: isPending, isSuccess } = useTradeshow(tradeshowId)
  const { mutateAsync: addVisitor } = useAddVisitor(tradeshowId)
  const [isLoading, setIsLoading] = useState(false)
  const isSmallDevice = useMediaQuery('(max-width: 864px)')
  const currentLanguage = language.split('-')[0]

  const eventEnded = tradeshow?.endDate && dayjs(tradeshow.endDate).isBefore(dayjs())

  const form = useForm<RegisteredVisitor>({
    initialValues: {
      hasConsent: false,
      addons: [],
      companions: [],
      price: '',
    },
    validate: {
      firstName: (value) => (value ? null : <Text sx={{ fontSize: '10px' }}>{t('common.invalid-first-name')}</Text>),
      lastName: (value) => (value ? null : <Text sx={{ fontSize: '10px' }}>{t('common.invalid-last-name')}</Text>),
      email: (value) => (isEmail(value || '') ? null : <Text sx={{ fontSize: '10px' }}>{t('common.invalid-email')}</Text>),
      companyName: (value) => (value ? null : <Text sx={{ fontSize: '10px' }}>{t('common.invalid-company')}</Text>),
      price: (value) => (value && value != '' ? null : <Text sx={{ fontSize: '10px' }}>{t('common.invalid-price')}</Text>),
    },
  })

  async function onSubmit(values: RegisteredVisitor) {
    setIsLoading(true)

    const { price, addons, companions, ...rest } = values

    const visitor = new Visitor({
      ...rest,
      tradeShowId: tradeshowId!,
      importedFrom: Source.DIRECT,
    } as Visitor)

    visitor.companions = companions

    const added = await addVisitor(visitor)
    let ids: string[] = []
    if (added instanceof Visitor) {
      ids = [added.id]
    } else {
      ids = added.map((v) => v.id)
    }

    await redirectToCheckout(tradeshowId!, ids, visitor.email, price, addons)

    setIsLoading(false)
    navigate('/success')
  }

  if (isPending) {
    return <Loading />
  }

  if (!isSuccess) {
    showNotification({
      title: t('common.error.title'),
      message: t('common.error.message'),
      color: 'red',
    })

    // TODO: redirect 500
    return null
  }

  if (eventEnded) {
    return <EventEnded />
  }

  return (
    <Box
      display="flex"
      w="100%"
      sx={{ height: '100vh', flexDirection: 'column', alignItems: 'center', width: isSmallDevice ? '100%' : '80%' }}
    >
      <Box p={20} display="flex" sx={{ width: isSmallDevice ? '100%' : '80%', justifyContent: 'space-between', alignItems: 'center' }}>
        <Logo width={150} />
        <ActionIcon variant="default" onClick={() => changeLanguage(currentLanguage === 'fr' ? 'en' : 'fr')} size={30}>
          <Text size="xs">{currentLanguage === 'fr' ? 'EN' : 'FR'}</Text>
        </ActionIcon>
      </Box>
      <Box mb={tradeshow.description ? 5 : 50} sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
        <Text weight={800} align="center" size="40px">
          {tradeshow.name}
        </Text>
      </Box>

      {tradeshow.description && (
        <Box mb={20} sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
          <RichTextEditor editable={false} content={tradeshow.description} />
        </Box>
      )}

      {tradeshow.config.scheduleLink && !isEmpty(tradeshow.config.scheduleLink.url) && (
        <Box mb={20} sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
          <Anchor
            color="custom-primary-color.0"
            sx={{ fontSize: '18px', lineHeight: '22px', color: 'white', fontWeight: 400, height: '50px' }}
            onClick={() => window.open(tradeshow.config.scheduleLink!.url, '_blank')}
          >
            <Text color="red" sx={{ fontSize: '16px', lineHeight: '22px', fontWeight: 400 }}>
              {currentLanguage === 'fr' ? tradeshow.config.scheduleLink.fr : tradeshow.config.scheduleLink.en}
            </Text>
          </Anchor>
        </Box>
      )}

      {tradeshow.config.sponsorLogo && (
        <>
          <Box mb={20} sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
            <Text align="center" size="20px">
              {t('register.our-sponsors')}
            </Text>
          </Box>

          <Box mb={20} sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
            <Image maw={600} src={tradeshow.config.sponsorLogo} />
          </Box>
        </>
      )}

      <RegisterNewVisitorForm
        form={form}
        onSubmit={onSubmit}
        isLoading={isLoading}
        isSmallDevice={isSmallDevice}
        hasFoodIncluded={tradeshow.config.hasFoodIncluded}
      />
    </Box>
  )
}

interface RegisterNewVisitorFormProps {
  form: UseFormReturnType<RegisteredVisitor, (values: RegisteredVisitor) => RegisteredVisitor>

  onSubmit: (values: RegisteredVisitor) => Promise<void>
  isLoading: boolean

  hasFoodIncluded: boolean
  isSmallDevice: boolean
}

function RegisterNewVisitorForm({ form, isLoading, onSubmit, isSmallDevice, hasFoodIncluded }: RegisterNewVisitorFormProps) {
  const { t } = useTranslation()
  const { tradeshowId } = useParams()
  const { data, isLoading: isLoadingPrices, isSuccess } = useTradeshowPrices(tradeshowId)
  const [showCompanion, setShowCompanion] = useState(false)

  const prices = data?.filter((price) => price.isTicket)
  const addons = data?.filter((price) => price.isAddon)
  const companions = data?.filter((price) => price.isCompanionPrice)
  const selectedPrice = prices?.find((price) => price.id === form.values.price)

  useEffect(() => {
    if (isLoadingPrices || !isSuccess) {
      return
    }

    if (prices?.length && form.values.price === '') {
      const price = prices.find((price) => !price.isSoldOut)

      if (price) {
        form.setFieldValue('price', price.id)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prices, isSuccess, isLoadingPrices])

  if (isLoadingPrices) {
    return <Loading />
  }

  if (!isSuccess) {
    return null
  }

  return (
    <Box
      display="flex"
      sx={{
        width: '100%',
        justifyContent: 'center',
        label: {
          fontSize: '14px !important',
        },
      }}
    >
      <form
        style={{ padding: isSmallDevice ? '0 10px' : '0', width: isSmallDevice ? '100%' : '70%', maxWidth: '600px' }}
        onSubmit={form.onSubmit(onSubmit)}
      >
        <Box display="flex" sx={{ gap: 10, alignItems: 'center' }}>
          <TextInput
            w="100%"
            size="lg"
            withAsterisk
            sx={{
              input: {
                borderRadius: '4px !important',
                fontSize: '16px !important',
              },
            }}
            label={t('visitor.firstName')}
            placeholder={t('visitor.firstName')}
            {...form.getInputProps('firstName')}
          />
          <TextInput
            w="100%"
            size="lg"
            withAsterisk
            sx={{
              input: {
                borderRadius: '4px !important',
                fontSize: '16px !important',
              },
            }}
            label={t('visitor.lastName')}
            placeholder={t('visitor.lastName')}
            {...form.getInputProps('lastName')}
          />
        </Box>
        <TextInput
          size="lg"
          withAsterisk
          sx={{
            input: {
              borderRadius: '4px !important',
              fontSize: '16px !important',
            },
          }}
          type="email"
          label={t('visitor.email')}
          placeholder={t('visitor.email')}
          mt={10}
          {...form.getInputProps('email')}
        />
        <Box mt={10} display="flex" sx={{ gap: 10, alignItems: 'center' }}>
          <TextInput
            size="lg"
            sx={{
              input: {
                borderRadius: '4px !important',
                fontSize: '16px !important',
              },
            }}
            w="100%"
            withAsterisk
            label={t('visitor.company')}
            placeholder={t('visitor.company')}
            {...form.getInputProps('companyName')}
          />
          <TextInput
            w="100%"
            size="lg"
            sx={{
              input: {
                borderRadius: '4px !important',
                fontSize: '16px !important',
              },
            }}
            label={t('visitor.company-phone')}
            placeholder={t('visitor.company-phone')}
            {...form.getInputProps('companyPhone')}
          />
        </Box>
        <Box mt={10} display="flex" sx={{ gap: 10, alignItems: 'center' }}>
          <TextInput
            w="100%"
            size="lg"
            sx={{
              input: {
                borderRadius: '4px !important',
                fontSize: '16px !important',
              },
            }}
            label={t('visitor.job-title')}
            placeholder={t('visitor.job-title')}
            {...form.getInputProps('jobTitle')}
          />
        </Box>

        {hasFoodIncluded && (
          <Box mt={10} display="flex" sx={{ gap: 10, alignItems: 'center' }}>
            <Textarea
              w="100%"
              size="lg"
              sx={{
                input: {
                  borderRadius: '4px !important',
                  fontSize: '16px !important',
                },
              }}
              label={t('visitor.dietary-restrictions')}
              placeholder={t('visitor.dietary-restrictions')}
              {...form.getInputProps('dietaryRestrictions')}
            />
          </Box>
        )}

        <Box mt={15} display="flex" sx={{ gap: 10, alignItems: 'center' }}>
          <Checkbox
            w="100%"
            size="xs"
            color="custom-primary-color.0"
            sx={{
              '.mantine-Checkbox-body': {
                alignItems: 'center',
              },
            }}
            label={t('visitor.consent')}
            {...form.getInputProps('hasConsent', { type: 'checkbox' })}
          />
        </Box>

        {!selectedPrice && (
          <Box mt={30} mb={20} display="flex" sx={{ width: '100%', justifyContent: 'center' }}>
            <Text fw={500}>
              <Text color="red" size="lg">
                {t('register.no-price-available')}
              </Text>
            </Text>
          </Box>
        )}

        {selectedPrice && (
          <>
            <Box mt={30} mb={20} display="flex" sx={{ width: '100%', borderBottom: '1px solid #cecece' }}>
              <Text fw={600} size="xl">
                {t('register.tickets')}
              </Text>
            </Box>

            <Box mt={10} display="flex" sx={{ gap: 10, flexFlow: 'column' }}>
              <Radio.Group value={form.values.price} onChange={(e) => form.setFieldValue('price', e)}>
                {prices?.map((price, i) => (
                  <Radio
                    sx={i > 0 ? { marginTop: 10 } : {}}
                    color="custom-primary-color.0"
                    disabled={price.isSoldOut}
                    label={
                      <Box display="flex" sx={{ alignItems: 'baseline', flexFlow: 'column' }}>
                        <Text fw={600} size="sm">
                          {price.name}
                          {price.waitingListUrl && (
                            <Anchor ml={3} color="custom-primary-color.0" onClick={() => window.open(price.waitingListUrl, '_blank')}>
                              {t('register.waiting-list')}
                            </Anchor>
                          )}
                        </Text>
                        <Text size="xs">{price.description}</Text>
                      </Box>
                    }
                    value={price.id}
                    key={price.id}
                  />
                ))}
              </Radio.Group>
            </Box>

            {(addons || companions) && (
              <>
                <Box mt={30} mb={20} display="flex" sx={{ width: '100%', borderBottom: '1px solid #cecece' }}>
                  <Text fw={600} size="xl">
                    {t('register.addons')}
                  </Text>
                </Box>

                <Box mt={10} display="flex" sx={{ gap: 10, flexFlow: 'column' }}>
                  {addons?.map((addon) => (
                    <Checkbox
                      color="custom-primary-color.0"
                      disabled={addon.isSoldOut}
                      label={
                        <Box display="flex" sx={{ alignItems: 'baseline', flexFlow: 'column' }}>
                          <Text fw={600} size="sm">
                            {addon.name}
                            {addon.waitingListUrl && (
                              <Anchor ml={3} color="custom-primary-color.0" onClick={() => window.open(addon.waitingListUrl, '_blank')}>
                                {t('register.waiting-list')}
                              </Anchor>
                            )}
                          </Text>
                          <Text size="xs">{addon.description}</Text>
                        </Box>
                      }
                      value={addon.id}
                      key={addon.id}
                      onChange={(e) => {
                        if (e.target.checked) {
                          form.setFieldValue('addons', [...(form.values.addons || []), e.target.value])
                        } else {
                          const index = form.values.addons?.findIndex((addonId) => addonId === e.target.value)
                          if (index !== undefined && index !== -1) {
                            const newAddons = [...(form.values.addons || [])]
                            newAddons.splice(index, 1)
                            form.setFieldValue('addons', newAddons)
                          }
                        }
                      }}
                    />
                  ))}
                  {companions?.map((companionPrice) => (
                    <Checkbox
                      color="custom-primary-color.0"
                      disabled={companionPrice.isSoldOut}
                      label={
                        <Box display="flex" sx={{ alignItems: 'baseline', flexFlow: 'column' }}>
                          <Text fw={600} size="sm">
                            {companionPrice.name}
                            {companionPrice.waitingListUrl && (
                              <Anchor
                                ml={3}
                                color="custom-primary-color.0"
                                onClick={() => window.open(companionPrice.waitingListUrl, '_blank')}
                              >
                                {t('register.waiting-list')}
                              </Anchor>
                            )}
                          </Text>
                          <Text size="xs">{companionPrice.description}</Text>
                        </Box>
                      }
                      value={companionPrice.id}
                      key={companionPrice.id}
                      onChange={(e) => {
                        if (e.target.checked) {
                          form.setFieldValue('addons', [...(form.values.addons || []), e.target.value])
                          form.insertListItem('companions', { firstName: '', lastName: '' }, 0)
                        } else {
                          form.setFieldValue(
                            'addons',
                            (form.values.addons || []).filter((addonId) => addonId !== e.target.value),
                          )
                          form.removeListItem('companions', 0)
                        }

                        setShowCompanion(e.currentTarget.checked)
                      }}
                    />
                  ))}
                </Box>
              </>
            )}

            {showCompanion && (
              <>
                <Box mt={30} mb={20} display="flex" sx={{ width: '100%', borderBottom: '1px solid #cecece' }}>
                  <Text fw={600} size="xl">
                    {t('register.companion')}
                  </Text>
                </Box>

                <Box mt={10} display="flex" sx={{ gap: 10, alignItems: 'center' }}>
                  <TextInput
                    size="lg"
                    sx={{
                      input: {
                        borderRadius: '4px !important',
                        fontSize: '16px !important',
                      },
                    }}
                    w="100%"
                    withAsterisk
                    label={t('visitor.firstName')}
                    placeholder={t('visitor.firstName')}
                    //TODO handle multiple companions
                    {...form.getInputProps('companions.0.firstName')}
                  />
                  <TextInput
                    w="100%"
                    size="lg"
                    sx={{
                      input: {
                        borderRadius: '4px !important',
                        fontSize: '16px !important',
                      },
                    }}
                    withAsterisk
                    label={t('visitor.lastName')}
                    placeholder={t('visitor.lastName')}
                    //TODO handle multiple companions
                    {...form.getInputProps('companions.0.lastName')}
                  />
                </Box>
                <Box mt={20} display="flex" sx={{ gap: 10, flexFlow: 'column' }}>
                  {addons?.map((addon) => (
                    <Checkbox
                      color="custom-primary-color.0"
                      disabled={addon.isSoldOut}
                      label={
                        <Box display="flex" sx={{ alignItems: 'baseline', flexFlow: 'column' }}>
                          <Text fw={600} size="sm">
                            {addon.name}
                            {addon.waitingListUrl && (
                              <Anchor ml={3} color="custom-primary-color.0" onClick={() => window.open(addon.waitingListUrl, '_blank')}>
                                {t('register.waiting-list')}
                              </Anchor>
                            )}
                          </Text>
                          <Text size="xs">{addon.description}</Text>
                        </Box>
                      }
                      value={addon.id}
                      key={addon.id}
                      onChange={(e) => {
                        if (e.target.checked) {
                          form.setFieldValue('addons', [...(form.values.addons || []), e.target.value])
                        } else {
                          const index = form.values.addons?.findIndex((addonId) => addonId === e.target.value)
                          if (index !== undefined && index !== -1) {
                            const newAddons = [...(form.values.addons || [])]
                            newAddons.splice(index, 1)
                            form.setFieldValue('addons', newAddons)
                          }
                        }
                      }}
                    />
                  ))}
                </Box>
              </>
            )}
          </>
        )}

        <Group display="flex" position="right" sx={{ marginTop: 30, marginBottom: 20 }}>
          <Button
            color="custom-primary-color.0"
            radius="md"
            disabled={isLoading || !form.isValid()}
            sx={{
              fontSize: '18px',
              lineHeight: '22px',
              color: 'white',
              fontWeight: 400,
              height: '50px',
            }}
            type="submit"
            loading={isLoading}
          >
            <Text sx={{ fontSize: '16px', lineHeight: '22px', fontWeight: 400 }}>{t('authentication.register')}</Text>
          </Button>
        </Group>
      </form>
    </Box>
  )
}
